import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

export function sleep(ms: number = 0): Promise<void> {
    console.log('Kindly remember to remove `sleep`');
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function arrayToggleBaseOnId(array: Array<any>, value: any) {
    const index = array.findIndex((el) => el.id === value.id);
    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

export function arrayHasBasedOnId(array: Array<any>, value: any) {
    return array.findIndex((el) => el.id === value.id) !== -1;
}

export function arrayToggle(array: Array<any>, value: any) {
    let index = array.indexOf(value);
    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

export function arrayHas(array: Array<any>, value: any) {
    console.log(array)
    console.log(value)
    return array.indexOf(value) !== -1;
}

export function isActionAvailable(array: Array<any>, action: string) {
    let isAvailable = true;
    for (const item of array) {
        isAvailable = item.actions.find((subItem: any) => subItem.action === action);
        if (!isAvailable) break;
    }
    return isAvailable;
}

export function getRoleIcone(role: string|undefined) {
    let roleToAnalyze = role;
    if (!roleToAnalyze) {
        const userStore = useUserStore();
        const { user } = storeToRefs(userStore);
        roleToAnalyze = user.value.role;
    }
    switch (roleToAnalyze) {
        case 'gestionnaireDeDossier':
            return "fas fa-user";
        case 'responsable':
            return "fas fa-user-tie";
        case 'admin':
            return "fas fa-user-secret";
        case 'superAdmin':
            return "fas fa-crown";
    }
}

export function getFolderIcone(state: string|undefined) {
    let stateToAnalyze = state;
    if (!stateToAnalyze) {
        const userStore = useUserStore();
        const { user } = storeToRefs(userStore);
        stateToAnalyze = user.value.role;
    }
    switch (stateToAnalyze) {
        case 'attente_validation':
            return "far fa-folder";
        case 'initialisation':
            return "fa-regular fa-folder-open";
        case 'done':
            return "fas fa-folder";
        default:
            return "fas fa-folder-open";
    }
}

export function getStepIndicator(state: string) {
    switch (state) {
        case 'attente_validation':
            return "0 / 5";
        case 'initialisation':
            return "1 / 5";
        case 'configuration':
            return "2 / 5";
        case 'validation':
            return "3 / 5";
        case 'montage':
            return "4 / 5";
        case 'termine':
            return "5 / 5";
    }
}

export function percentage(partialValue: number, totalValue: number) {
    return (100 * partialValue) / totalValue;
}

export function randomColor(id: string) {
    let str = id.replace(/\D/g, '');
    switch(Math.trunc(Number(str)/1000000 % 16)) {
        case 0:
            return '#000';
        case 1:
            return '#34568B';
        case 2:
            return '#FF6F61';
        case 3:
            return '#6B5B95';
        case 4:
            return '#88B04B';
        case 5:
            return '#F7CAC9';
        case 6:
            return '#92A8D1';
        case 7:
            return '#955251';
        case 8:
            return '#B565A7';
        case 9:
            return '#009B77';
        case 10:
            return '#DD4124';
        case 11:
            return '#D65076';
        case 12:
            return '#9B2335';
        case 13:
            return '#E15D44';
        case 14:
            return '#98B4D4';
        case 15:
            return '#7FCDCD';
        case 16:
            return '#DFCFBE';
    }
}
